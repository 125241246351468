import { Constructor, DestroyableMixin } from '@asp/mixins';
import { inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  Observable,
  shareReplay,
  Subject,
} from 'rxjs';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { tuiIsPresent } from '@taiga-ui/cdk';

// eslint-disable-next-line @typescript-eslint/ban-types
export const DataTableMixin = <Base extends Constructor<{}>>(
  superClass: Base,
) => {
  class DataTable extends DestroyableMixin(superClass) {
    apollo?: Apollo;
    total$ = new BehaviorSubject<number>(undefined);
    init$ = new BehaviorSubject<boolean>(false);
    loading$ = new BehaviorSubject<boolean>(true);
    readonly direction$ = new Subject<-1 | 1>();
    readonly sorter$ = new Subject<string | number | symbol>();
    readonly pagination$ = new Subject();
    readonly filters$ = new BehaviorSubject(undefined);
    readonly morevars$ = new BehaviorSubject<object>(undefined);

    data$: Observable<any>;
    readonly request$ = combineLatest([
      this['sorter$'],
      this['direction$'],
      this['pagination$'],
      this['filters$'],
      this['morevars$'],
    ]).pipe(
      takeUntil(this.destroyed),
      tap(() => this.loading$.next(true)),
      // zero time debounce for a case when both key and direction change
      debounceTime(0),
      map(([sorter, direction, pagination, filters, morevars]) => {
        const vars = {};
        const _sorter = this['mapFields'](sorter);
        if (_sorter) {
          vars['order'] = {};
          vars['order'][_sorter] = direction === -1 ? 'DESC' : 'ASC';
        }
        if (
          pagination &&
          pagination['page'] !== undefined &&
          pagination['size'] !== undefined
        ) {
          vars['pagination'] = {};
          vars['pagination']['offset'] =
            pagination['page'] * pagination['size'];
          vars['pagination']['limit'] = pagination['size'];
        }
        if (filters && Object.keys(filters).length > 0) {
          vars['filters'] = filters;
        }
        if (morevars && Object.keys(morevars).length > 0) {
          vars['morevars'] = morevars;
        }
        return vars;
      }),
      map((vars, index) => [vars, index]),
      tap(([vars, index]) => {
        if (Object.keys(vars).length > 0 && this['objsQuery']) {
          if (vars['morevars'] !== undefined) {
            const morevars = vars['morevars'];
            delete vars['morevars'];
            vars = { ...vars, ...morevars };
          }
          this['objsQuery'].setVariables(vars).then(() => {
            if (index === 0) {
              // If this is not set then setVariables stops working cause of apollo internals
              this['objsQuery'].setOptions({
                errorPolicy: 'ignore',
              });
              this['data$'] = this['objsQuery'].valueChanges.pipe(
                takeUntil(this.destroyed),
                filter(tuiIsPresent),
                tap(({ loading }) => this['loading$'].next(loading)),
                tap(({ data }) => {
                  if (data) {
                    this['total$'].next(data[this['objName']]['totalCount']);
                  }
                }),
                map(({ data }) => {
                  if (data) {
                    return data[this['objName']].results;
                  }
                  return [];
                }),
                startWith([]),
                shareReplay(1),
              );
              this.init$.next(true);
            }
          });
        }
      }),
    );

    constructor(...args: any[]) {
      super(...args);
      if (!this.apollo) {
        this.apollo = inject(Apollo);
      }
      this.request$.subscribe();
    }

    onPaginationChange(event: any) {
      this['pagination$'].next(event);
    }
  }

  return DataTable;
};
