import { Constructor } from '@asp/mixins';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  ViewChild,
} from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-types
export const WithMenubarMixin = <Base extends Constructor<{}>>(
  superClass: Base,
) => {
  @Directive()
  class WithMenubar extends superClass implements AfterViewInit {
    cdr: ChangeDetectorRef;

    @ViewChild('main', { static: false }) main: ElementRef;
    @ViewChild('navbar', { static: false }) navbar: ElementRef;
    @ViewChild('root', { static: false }) root: ElementRef;

    constructor(...args: any[]) {
      super(...args);
      if (!this.cdr) {
        this.cdr = inject(ChangeDetectorRef);
      }
    }

    ngAfterViewInit() {
      // check if super has ngAfterViewInit and call it if it does
      if (super['ngAfterViewInit']) {
        super['ngAfterViewInit']();
      }
      this.changeHeight();
      this.cdr.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    changeHeight() {
      this.root.nativeElement.style.height =
        window.innerHeight - this.navbar.nativeElement.offsetHeight + 'px';
      this.cdr.detectChanges();
    }
  }

  return WithMenubar;
};
