import { Constructor } from '@asp/mixins';
import { Directive, inject, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, shareReplay } from 'rxjs';
import { Apollo, QueryRef } from 'apollo-angular';
import { CURRENT_USER } from '@asp/graphql';
import { map } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/ban-types
export const UserAwareMixin = <Base extends Constructor<{}>>(
  superClass: Base,
) => {
  class UserAware extends superClass {
    apollo?: Apollo;

    currentUser: QueryRef<any>;

    currentUser$: Observable<any>;

    constructor(...args: any[]) {
      super(...args);
      if (!this.apollo) {
        this.apollo = inject(Apollo);
      }
      this.currentUser = this.loadUser();
      this.currentUser$ = this.currentUser.valueChanges.pipe(
        map(({ data }: any) => data['currentUser']),
        shareReplay(1),
      );
    }

    loadUser(): QueryRef<any> {
      return this.apollo.watchQuery({
        query: CURRENT_USER,
      });
    }
  }

  return UserAware;
};
// eslint-disable-next-line @typescript-eslint/ban-types
export const DestroyableMixin = <Base extends Constructor<{}>>(
  superClass: Base,
) => {
  @Directive()
  class Destroyable extends superClass implements OnDestroy {
    destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnDestroy(): void {
      if (super['ngOnDestroy']) {
        super['ngOnDestroy']();
      }
      this.destroyed.next(true);
      this.destroyed.complete();
    }
  }

  return Destroyable;
};
